const QuestionCategories = [
  {
    Title: "Ethnicity, Religion",
    Description:
      "We ask questions about your ethnicity and religion. This is how the system finds your most compatible matches.",
    Percentage: 100,
    Color: "#8777D9",
    Questions: [
      {
        questionID: 1,
        question: "You Religion",
        type: "multiple_choice;single",
        answers: [
          "Hindu",
          "Muslim",
          "Christian - Catholic",
          "Christian - Protestant",
          "Christian - Other",
          "Sikh",
          "Jain - Digambar",
          "Jain - Shwetambar",
          "Jain - Other",
          "Parsi",
          "Buddhist",
          "Jewish",
          "Inter-Religion",
          "Spiritual",
          "Agnostic",
          "Atheist",
        ],
      },
      {
        questionID: 2,
        question: "You Caste",
        type: "multiple_choice;single",
        answers: [
          "Unspecified",
          "Born Again",
          "Brethren",
          "Church of South India",
          "Evangelist",
          "Jacobite",
          "Knanaya",
          "Knanaya Catholic",
          "Knanaya Jacobite",
          "Latin Catholic",
          "Malankara",
          "Marthoma",
          "Others",
          "Pentecost",
          "Roman Catholic",
          "Seventh-day Adventist",
          "Syrian Catholic",
          "Syrian Jacobite",
          "Syrian Orthodox",
          "Syro Malabar",
          "Adi Dravida - Arunthathiyar - Boyar - Brahmin/Gurukkal",
          "Brahmin/ıyengar",
          "Brahmin/iyer",
          "Brahmin/Other",
          "Chattada Sri Vaishnava",
          "Chettiar",
          "Devandra Kula Vellalar",
          "Devar / Thevar / Mukkulathor",
          "Gounder Gramani",
          "Karuneegar",
          "Kongu Vellanava Gounder",
          "Krishnavaka",
          "Kulalar",
          "Kuravan",
          "Kurumbar",
          "Maruthuvar",
          "Meenavar",
          "Mudaliyar",
          "Muthuraja",
          "Nadar",
          "Naicker",
          "Naidu",
          "Pandaram",
          "Parkava Kulam",
          "Pillai",
          "Reddy",
          "SC",
          "SenaiThalaivar",
          "Senguntha Mudaliyar",
          "Soni",
          "Sozhiya Vellalar",
          "ST",
          "Urali Gounder",
          "Valluvan",
          "Vannar",
          "Vannia Kula Kshatriyar",
          "Veera Saivam",
          "Vellalar",
          "Vettuva Gounder",
          "Vishwakarmu",
          "Vokkaliga",
          "Yadav",
          "Ansari",
          "Arain",
          "Awan",
          "Bohra",
          "Dekkani",
          "Dudekula",
          "Hanafı",
          "Jat",
          "Khoja",
          "Lebbai",
          "Malik",
          "Mapila",
          "Maraicar",
          "Memon",
          "Mughal",
          "Rajput",
          "Rowther",
          "Shafi",
          "Sheikh",
          "Siddiqui",
          "Syed",
          "Pathan",
          "Qureshi",
          "Ahluwalia",
          "Arora",
          "Bhatia",
          "Bhatra",
          "Ghumar",
          "Kamboj",
          "Khatri",
          "Kshatriya",
          "Lubana",
          "Majabi",
          "Nai",
          "Ramdasia",
          "Ramgharia",
          "Ravidasia",
          "Ahluwalia",
          "Arora",
          "Bhatia",
          "Bhatra",
          "Ghumar",
          "Kamboj",
          "Khatri",
          "Kshatriya",
          "Lubana",
          "Majabi",
          "Nai",
          "Rajput",
          "Ramdasia",
          "Ramgharia",
          "Ravidasia",
          "Don't wish to specify",
        ],
      },
      {
        questionID: 3,
        question: "You Community",
        type: "multiple_choice;single",
        answers: [
          "Aka",
          "Arabic",
          "Arunachali",
          "Assamese",
          "Awadhi",
          "Baluchi",
          "Bengali",
          "Bhojpuri",
          "Bhutia",
          "Brahui",
          "Brij",
          "Burmese",
          "Chhattisgarhi",
          "Chinese",
          "Coorgi",
          "Dogri",
          "English",
          "French",
          "Garhwali",
          "Garo",
          "Gujarati",
          "Haryanavi",
          "Himachali/Pahari",
          "Hindi",
          "Hindko",
          "Kakbarak",
          "Kanauji",
          "Kannada",
          "Kashmiri",
          "Khandesi",
          "Khasi",
          "Konkani",
          "Koshali",
          "Kumaoni",
          "Kutchi",
          "Ladakhi",
          "Lepcha",
          "Magahi",
          "Maithili",
          "Malay",
          "Malayalam",
          "Manipuri",
          "Marathi",
          "Marwari",
          "Miji",
          "Mizo",
          "Monpa",
          "Nepali",
          "Odia",
          "Pashto",
          "Persian",
          "Punjabi",
          "Rajasthani",
          "Russian",
          "Sanskrit",
          "Santhali",
          "Seraiki",
          "Sindhi",
          "Sinhala",
          "Sourashtra",
          "Spanish",
          "Swedish",
          "Tagalog",
          "Tamil",
          "Telugu",
          "Tulu",
          "Urdu",
          "Other",
        ],
      },
    ],
  },
  {
    Title: "Education, Occupation",
    Description:
      "We ask questions about your education level, your job, and your Occupation. This is how the system finds your most compatible matches.",
    Percentage: 60,
    Color: "#FFC400",
    Questions: [
      {
        questionID: 4,
        question: "Where did you graduate in your class?",
        type: "multiple_choice;single",
        answers: ["Top 5%", "Top 20%", "Top Half", "Bottom Half"],
      },
      {
        questionID: 5,
        question: "What is your highest qualification?",
        type: "multiple_choice;single",
        answers: [
          "Doctorate",
          "M.Phil",
          "Masters",
          "Honours",
          "Bachelors",
          "Diploma",
          "High School",
          "Less than High School",
        ],
      },
      {
        questionID: 6,
        question: "Occupation?",
        type: "multiple_choice;single",
        answers: [
          "Actor/Model",
          "Advertising Professional",
          "Film/ Entertainment Professional",
          "Media Professional",
          "Agriculture Professional",
          "Farming",
          "Architect",
          "Chartered Accountant",
          "Corporate Management Professional",
          "Education Professional",
          "Educational Institution Owner",
          "Librarian",
          "Professor/Lecturer",
          "Research Assistant",
          "Research Professional",
          "Science Professional",
          "Scientist",
          "Teacher",
          "Animator",
          "Developer",
          "Product Designer",
          "Fashion Designer",
          "Interior Designer",
          "Hotels/ Hospitality Professional",
          "Lawyer & Legal Professional",
          "Medical/ Healthcare Professional",
          "Nurse",
          "Paramedic",
          "Pharmacist",
          "Physiotherapist",
          "Psychologist",
          "Veterinary Doctor",
          "Dentist",
          "Doctor",
          "Surgeon",
          "C*O /Chairman/ President/ Director",
          "VP/ AVP/ GM/ DGM",
          "Artist",
          "Beautician",
          "Broker",
          "Business Owner/ Entrepreneur",
          "Fitness Professional",
          "Singer",
          "Social Services/NGO/ Volunteer",
          "Sportsperson",
          "Travel Professional",
          "Writer",
          "Others",
        ],
      },
    ],
  },
  {
    Title: "Hobbies", 
    Description:
      "We ask questions about your preferences and hobbies. This is how the system finds your most compatible matches.",
    Percentage: 90,
    Color: "#57D9A3",
    Questions: [
      {
        questionID: 102,
        question: "This is question 1?",
        answers: "This is the first answers",
      },
      {
        questionID: 202,
        question: "This is question 2?",
        answers: "This is the second answers",
      },
    ],
  },
  {
    Title: "Physical Characteristics",
    Description:
      "We ask questions about your face and body types. This is how the system finds your most compatible matches.",
    Percentage: 80,
    Color: "#FF7452",
    Questions: [
      {
        questionID: 103,
        question: "This is question 1?",
        answers: "This is the first answers",
      },
      {
        questionID: 303,
        question: "This is question 2?",
        answers: "This is the second answers",
      },
    ],
  },
  {
    Title: "Lifestyle & Habits",
    Description:
      "We ask questions about your lifestyle and habits. This is how the system finds your most compatible matches.",
    Percentage: 50,
    Color: "#2684FF",
    Questions: [
      {
        questionID: 104,
        question: "This is question 1?",
        answers: "This is the first answers",
      },
      {
        questionID: 404,
        question: "This is question 2?",
        answers: "This is the second answers",
      },
    ],
  },
  {
    Title: "Kundali",
    Description:
      "We ask questions about your star and raasi. This is how the system finds your most compatible matches.",
    Percentage: 23,
    Color: "#00C7E6",
    Questions: [
      {
        questionID: 105,
        question: "This is question 1?",
        answers: "This is the first answers",
      },
      {
        questionID: 707,
        question: "This is question 2?",
        answers: "This is the second answers",
      },
    ],
  },
];

export default QuestionCategories;
