//Styling
import './index.css';

//Packages
import React from 'react';
import ReactDOM from 'react-dom';

//Components
import App from './App';
const root = document.getElementById('root');

ReactDOM.render(<React.StrictMode> <App /> </React.StrictMode>, root);