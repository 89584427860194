//Packages
import React from 'react';

//Source Code
import PageRoutes from './Routes/PageRoutes';

// Components
function App() {
  return (
    <>
      <PageRoutes />
    </>
  );
}

export default App;